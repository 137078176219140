import { getAuth } from '@/entities/auth/api/get-auth';
import { queryOptions } from '@tanstack/react-query';
import { getSubscription } from '@/entities/auth/api/get-subscription';

export const authQueryKeys = {
  all: () => ['auth'],
  me: () => [...authQueryKeys.all(), 'me'],
  subscription: () => [...authQueryKeys.all(), 'subscribe'],
};

export const authQueries = {
  me: () =>
    queryOptions({
      queryKey: authQueryKeys.me(),
      retry: false,
      queryFn: getAuth,
      staleTime: Infinity,
    }),
  subscription: () =>
    queryOptions({
      queryKey: authQueryKeys.subscription(),
      queryFn: getSubscription,
      select: (res) => res.data,
    }),
};
