'use client';

import React from 'react';
import { Button } from '@/shared/ui';
import { ROUTES } from '@/shared/const';
import { useAuth } from '@/entities/auth';

export const SignOutButton = () => {
  const { user, logout } = useAuth();

  const onClick = async () => {
    await logout();
    window.location.href = ROUTES.HOME;
    // router.push(ROUTES.HOME);
  };

  if (!user) {
    return null;
  }

  return (
    <Button onClick={onClick} variant="outline">
      로그아웃
    </Button>
  );
};
