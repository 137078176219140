import { useQuery, useQueryClient } from '@tanstack/react-query';
import { postRefreshToken } from '@/entities/auth/api/post-refresh-token';
import {
  authQueries,
  authQueryKeys,
  Credentials,
  getAuth,
  postLoginWithEmail,
  postLogout,
} from '@/entities/auth';
import { ROUTES } from '@/shared/const';

export const useAuth = () => {
  const queryClient = useQueryClient();

  const { data: user, isLoading } = useQuery(authQueries.me());

  const loginWithEmail = async (credentials: Credentials) => {
    // 로그인 로직 구현
    // 성공 시 사용자 정보를 쿼리 캐시에 설정
    await postLoginWithEmail(credentials);
    window.location.href = ROUTES.MY_PAGE.ROOT;
  };

  const logout = async () => {
    await postLogout();
    await queryClient.invalidateQueries();
    queryClient.removeQueries({ queryKey: authQueryKeys.me() });
    window.location.href = ROUTES.HOME;
  };

  const refreshToken = async () => {
    try {
      await postRefreshToken();
      // 토큰 갱신 후 사용자 정보를 다시 가져옵니다
      const user = await getAuth();
      queryClient.setQueryData(authQueryKeys.me(), user);
    } catch (error) {
      // 리프레시 실패 시 로그아웃 처리
      await logout();
    }
  };

  return {
    user: user?.data ?? null,
    isLoading,
    loginWithEmail,
    logout,
    refreshToken,
  };
};
