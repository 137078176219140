import { ROUTES } from '@/shared/const';
import {
  Avatar,
  buttonVariants,
  AvatarImage,
  AvatarFallback,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from '@/shared/ui';
import Link from 'next/link';
import React from 'react';
import { User } from '@/shared/type/user';
import { cn } from '@/shared/lib';
import { LogOut, Settings } from 'lucide-react';

type Props = {
  user: User | null;
  className?: string;
  onLogout: () => void;
};

export const Profile = ({ user, className, onLogout }: Props) => {
  if (!user) {
    return (
      <Link
        href={ROUTES.AUTH.SIGN_IN}
        className={buttonVariants({ variant: 'ghost' })}
      >
        선생님 회원 로그인
      </Link>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn('flex items-center gap-x-3', className)}
      >
        <Avatar>
          <AvatarImage src="https://github.com/shadcn.png" />
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
        <span className="text-lg">{user?.nickname} 선생님</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link href={ROUTES.SETTING.PROFILE}>
            <Settings className="mr-2 h-4 w-4" />
            <span>Settings</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onLogout}>
          <LogOut className="mr-2 h-4 w-4" />
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
