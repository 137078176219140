import * as z from 'zod';

export const Credentials = z.object({
  email: z
    .string({ required_error: '이메일을 입력해주세요.' })
    .email({ message: '이메일 형식이 올바르지 않습니다.' }),
  password: z
    .string({ required_error: '비밀번호를 입력해주세요.' })
    .min(6, { message: '비밀번호는 6글자 이상이어야 합니다.' }),
});

export type Credentials = z.infer<typeof Credentials>;

export const TwoFactorWithCredential = z.object({
  email: z
    .string({ required_error: '이메일을 입력해주세요.' })
    .email({ message: '이메일 형식이 올바르지 않습니다.' }),
  code: z
    .string({ required_error: '인증 코드를 입력해주세요.' })
    .min(6, { message: '인증 코드는 6자리여야 합니다.' })
    .max(6, { message: '인증 코드는 6자리여야 합니다.' }),
});

export type TwoFactorWithCredential = z.infer<typeof TwoFactorWithCredential>;

export const SetupTwoFactorState = Credentials.extend({
  twoFactorState: z.boolean(),
});

export type SetupTwoFactorState = z.infer<typeof SetupTwoFactorState>;
