'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import { Navigation } from '@/widgets/header/ui/Navigation';
import { SignOutButton } from '@/features/sign-out';
import { MainLogo } from '@/shared/ui';
import { useWindowScroll } from 'react-use';
import { Profile, useAuth } from '@/entities/auth';
import { cn } from '@/shared/lib';

export const Header = () => {
  const pathName = usePathname();
  const hideNavigation = pathName?.includes('my-page');
  const { user, logout } = useAuth();
  const { y } = useWindowScroll();
  const isScrolled = y > 0;

  if (pathName?.includes('auth')) {
    return;
  }

  return (
    <header
      className={cn(
        'sticky top-0 z-10 mx-auto flex h-16 w-full items-center justify-between px-20',
        { 'bg-white': isScrolled, 'bg-transparent': !isScrolled },
      )}
    >
      <div className="flex h-full flex-1 items-center">
        <MainLogo className="h-3/5 cursor-pointer" />
      </div>
      {!hideNavigation && <Navigation />}
      <div className="flex flex-1 items-center justify-end gap-x-4">
        <Profile user={user} onLogout={logout} />
        <SignOutButton />
      </div>
    </header>
  );
};
