import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/public/images/logo/q-craft-logo.webp");
import(/* webpackMode: "eager" */ "/app/public/images/main/preview-image.png");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/accordion.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/avatar.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/checkbox.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/data-table.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/dialog.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/dropdown-menu.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/form.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/label.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/popover.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/progress.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/radio-group.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/select.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/separator.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/slider.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/toast.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/toaster.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui/use-toast.ts");
import(/* webpackMode: "eager" */ "/app/src/widgets/header/index.tsx")