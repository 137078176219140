import { Button } from '@/shared/ui';
import Link from 'next/link';
import React from 'react';

export const Navigation = () => {
  return (
    <nav className="flex flex-1 justify-center">
      <Button variant="ghost">
        <Link href="https://q-craft.co.kr">서비스 소개</Link>
      </Button>
      <Button variant="ghost">
        <Link href="https://q-craft.co.kr/%ea%b0%80%ea%b2%a9%ec%86%8c%ea%b0%9c/">
          요금제
        </Link>
      </Button>
    </nav>
  );
};
